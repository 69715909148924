import React from "react"
import { FaSpinner } from "react-icons/fa";




function Loading(props) {



        return (
            <>


                <div className="absolute top-0 left-0 w-full h-full bg-black opacity-70 z-10" ></div>

                <div className="absolute top-[50%] translate-y-[-50%] right-[50%] translate-x-[50%] z-20  text-white">
                    <FaSpinner className="size-24 animate-spin" />

                </div>





            </>
        )
    
}


export default Loading