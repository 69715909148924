import React, {useState, useEffect, useContext} from "react";
import MainNav from "./MainNav"
import { FaBug } from "react-icons/fa";
import ErrorMessage from "./ErrorMessage"
import {EndpointContext} from "./Contexts"

let timeout;

function ReportBug(props) {
    const endpoint = useContext(EndpointContext)

    const [severity, setSeverity] = useState(0)
    const [issue, setIssue] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const callBug = () => {
        return new Promise(async(resolve) => {
            const response = await fetch(endpoint+'/reportBug', {
                method: "POST",
                mode: "cors",
                credentials: "include",
                headers: {
                  "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    message: issue,
                    rating: severity
                })
            })

            resolve(response.json())
        })
    }


    const processBug = () => {
        callBug().then((res) => {
            if (res.message === "not logged in") {
                window.location.replace("/login")
            } else if (res.message === "success") {
                setErrorMessage("Bug Reported")
            } else {
                setErrorMessage("Something went wrong")
            }
        })

    }

    useEffect(() => {
        timeout = setTimeout(() => {
            setErrorMessage("")
        }, 3000)
        
        return () => {
            clearTimeout(timeout)
    
        }

    }, [errorMessage])








    return (
        <div className="sm:w-2/6 w-full mx-auto sm:mt-24 p-8 bg-base-300 rounded-lg h-screen relative">
            {(errorMessage !== "") && (
                <ErrorMessage error={errorMessage} />
            )}
            

            <p className="text-center font-1 font-bold text-3xl">Report your Bug</p>
            <div className="bg-base-200 p-4 mt-4 rounded-lg">
            <div className="p-4 bg-base-100 rounded-lg">
                <p className="font-2 text-lg font-semibold">Describe your issue:</p>
                <textarea onChange={(e) => setIssue(e.target.value)} className="textarea textarea-lg textarea-primary w-full mt-2 text-lg font-1 font-semibold" placeholder="After I swipe right on someone, the page just crashes and sends me to the login screen" />
            </div>
            <div className="p-4 bg-base-100 mt-2 rounded-lg">
                <p className="font-2 text-lg font-semibold">How severe is this issue?</p>
                <div className="w-3/6 mt-2">
                    <input value={severity} type="range" step="1" max="10" min="0" className="range range-accent" onChange={(e) => {
                        setSeverity(e.target.value)
                    }} />
                </div>
                
                
            </div>
            <div className="mx-auto w-fit mt-3">
                <button className="btn btn-wide btn-accent" onClick={processBug}><p className="font-2 text-lg ">Report Bug</p><FaBug className="size-4" /></button>
            </div>
            </div>
            
            
            

        </div>
       
    )
}


export default ReportBug