import React from "react"
import { FaInstagram } from "react-icons/fa";


function Footer(props) {




    return (


        <section data-theme='cupcake'>


            <div className="footer footer-center bg-primary py-4">


            <aside className="leading-3">
            <p className="font-1 text-2xl font-bold">Laylnk</p>
            <p className="font-1 text-base font-semibold">Expand your dating life</p>
            
            
            <div className="mt-2">
                <p className="font-2 text-sm font-semibold">A Hernandez Production</p>
                <p className="font-2 text-sm">Copyright © {new Date().getFullYear()}</p>
                

            </div>

            <div className="mt-4">
                <a href="https://www.instagram.com/mocotinderofficial/?hl=en" className="btn btn-circle text-2xl btn-ghost">
                    <FaInstagram />
                </a>
                
            </div>

            <div>

            </div>


            </aside>
            </div>



        </section>
    )
}


export default Footer