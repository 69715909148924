import React from "react"

import logo from "./logo.png"


function Navbar(props) {




    return(

        <section data-theme="cupcake">
            <div className="sticky block top-0 left-0 z-20">


            <div className="navbar bg-primary w-full p-5">
            <div className="flex-1">
                <a href="/" className="btn btn-ghost btn-secondary ml-4" >
                    <p className="font-1 font-semibold text-3xl text-base-100 flex flex-row items-center gap-2"><img alt="logo" className="w-6" src={logo} />Laylnk</p>
                </a>
                

            </div>
            
            <div>
                {/* This would be the over side of the navbar */}
                <button className="btn btn-ghost btn-accent mr-2 sm:block hidden">

                    <a href="/tos" className="font-2 text-lg font-bold text-base-100">Terms of Service</a>

                </button>

                <button className="btn btn-accent">

                    <a href="/main" className="font-2 text-lg font-bold text-base-100">Login</a>

                </button>
            </div>
            </div>




            </div>
            



        </section>
    )
}




export default Navbar