import React, {useContext, useEffect} from "react"
import { FaInbox, FaCog, FaBug, FaHeart} from "react-icons/fa";
import {UserContext }from "./Contexts"




function MainNav(props) {

    const currentUser = useContext(UserContext)

    



    return (


        <>

        <div className="sticky top-0 left-0 sm:w-[20vw] w-full bg-base-300 min-w-fit min-h-screen">
    
            <div className="w-full h-full min-h-screen p-2 flex flex-col gap-2 relative">

                <div className="bg-base-100 rounded-lg p-4">
                    <div className="avatar">
                        <div className="w-20 rounded-full">
                        {/* add the [0 here later] */}
                            {(currentUser.images !== undefined) && (
                                
                                <img alt="Profile" src={"https://images-laylnk.s3.amazonaws.com/"+currentUser.images[0]} />
                            )}
                            


                        </div>

                    </div>
                    <p className="font-2 font-extrabold text-lg">{currentUser.name}</p>



                </div>

                <div className="bg-base-200 p-4 rounded-lg">
                    <p className="font-1 text-lg font-semibold">Quick Actions</p>


                    <div className="flex flex-col gap-2 w-full mt-2">
                        <div className="">



                            <div className="flex flex-row gap-2 p-4 bg-base-300 select-none cursor-pointer hover:bg-accent transition-all rounded-lg relative" onClick={() => {
                                
                                const whiteSpace = Array(Math.floor(Math.random()*100)).fill(" ")
                                let initialString = "Notifications"
                                whiteSpace.map((value) => {
                                    initialString += value
                                })
                                props.setMenu(initialString)
                               
                                }}>
                                <FaInbox className="text-lg mt-1" />
                                {(props.notifications > 0) && (
                                    <>
                                    <div className="absolute right-2 top-[50%] translate-y-[-50%] h-10 w-10 bg-red-500 rounded-full">
                                    <div className="relative w-full h-full">
                                        <p className="font-1 text-lg font-semibold text-white center">{props.notifications}+</p>
                                    </div>
                                    
                                

                                </div>

                                    </>
                                )}
                                <p className="font-1 font-bold">Notifications</p>
                                
                            </div>
                            
                        </div>
                        <div className="">



                            <div className="flex flex-row gap-2 p-4 bg-base-300 select-none cursor-pointer hover:bg-accent transition-all rounded-lg" onClick={() => {
                                const whiteSpace =Array(Math.floor(Math.random()*100)).fill(" ")
                                let initialString = "Settings"
                                whiteSpace.map((value) => {
                                    initialString += value
                                })
                                props.setMenu(initialString)
                                }}>
                               
                                <FaCog className="text-lg mt-1" />
                                <p className="font-1 font-bold">Settings</p>
                            </div>
                            
                        </div>
                        <div className="">



                            <div className="flex flex-row gap-2 p-4 bg-base-300 select-none cursor-pointer hover:bg-accent transition-all rounded-lg" onClick={(e) => {
                                const whiteSpace =Array(Math.floor(Math.random()*100)).fill(" ")
                                let initialString = "Bug"
                                whiteSpace.map((value) => {
                                    initialString += value
                                })
                                
                                props.setMenu(initialString)
                            }}>
                                <FaBug className="text-lg mt-1" />
                                <p className="font-1 font-bold">Report Bug</p>
                            </div>
                            
                        </div>
                        <div className="">



                            <div className="flex flex-row gap-2 p-4 bg-base-300 select-none cursor-pointer hover:bg-accent transition-all rounded-lg" onClick={(e) => {
                                const whiteSpace = Array(Math.floor(Math.random()*100)).fill(" ")
                                let initialString = "Feed"
                                whiteSpace.map((value) => {
                                    initialString += value
                                })
                                props.setMenu(initialString)
                            }}>
                                <FaHeart className="text-lg mt-1" />
                                <p className="font-1 font-bold">Feed</p>
                            </div>
                            
                        </div>
                        <div className="">



                            {/* <div className="flex flex-row gap-2 p-4 bg-base-300 select-none cursor-pointer hover:bg-accent transition-all rounded-lg">
                                <FaFlag className="text-lg mt-1" />
                                <p className="font-1 font-bold">Report User</p>
                            </div> */}
                            
                        </div>

                    </div>

                    
                </div>
                <div className="absolute p-2 bg-secondary w-full bottom-0 left-0 h-[10vh]">
                    <p className="font-1 text-2xl font-bold">Laylnk</p>
                    <p className="font-2 font-semibold">A Hernandez Production</p>

                </div>
            </div>

        </div>


        



        </>
    )
}



export default MainNav