import React, {useState, useEffect, useContext} from "react" 


import {FaSadCry, FaCheck, FaSearch, FaSpinner} from "react-icons/fa";


import {EndpointContext} from "./Contexts"

function Setup(props) {

    const endpoint = useContext(EndpointContext)
    const callSetup = (id) => {
        
        return new Promise(async(resolve) => {
            console.log("heres the id", id)
            const body = {
                receiver: id
            }

            const response = await fetch(endpoint + "/setup", {
                method: "POST",
                mode: "cors",
                credentials: "include",
                headers: {
                  "Content-Type": "application/json"
                },
                body: JSON.stringify(body)
            })

            resolve(response.json())
        })

    }
    

    


    const [menu, setMenu] = useState(false)
    const [quickSet, setQuickSet] = useState([])
    const [results, setResults] = useState([
        {
            name: "John S.",
            img: "https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg"
        },
        {
            name: "John S.",
            img: "https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg"
        },
        {
            name: "John S.",
            img: "https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg"
        }
        
    ])

    const processSetup = (id, i) => {
        


        callSetup(id).then((res) => {
            if (res.code === 'err') {
                // window.location.replace("/login")
            } else {
                setQuickSet((prevQuick) => {
                    let iterQuick = [...prevQuick]
                    iterQuick.splice(i, 1)
                    return iterQuick
                })
            }
        })
    }
    

    

    const callQuick = () => {
        return new Promise(async(resolve) => {
            const response = await fetch(endpoint + "/quickset", {
                method: "POST",
                mode: "cors",
                credentials: "include",
                headers: {
                  "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    personReq: props.currentUser.uuid
                })
            })

            resolve(response.json())




        }
    
    )

    }



    useEffect(() => {

        if (menu) {
            callQuick().then((res) => {
                if (res.code === "err") {
                    window.location.replace("/login")
                } else if (res.code === 'ok') {
                    setQuickSet(res.message)
                } 
            })
            
        }

    },[menu])



    const pronouns = "them"
    const randomPeople = [
        {
            name: "John S.",
            img: "https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg"
        },
        {
            name: "John S.",
            img: "https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg"
        },
        {
            name: "John S.",
            img: "https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg"
        }
        
    ]





    return (

        <>
        <div className="absolute top-0 left-0 z-10 h-full w-full select-none bg-base-100 opacity-50"></div>
            
            {(menu) && (
                <>
                    <div className="z-20 absolute left-0 right-0 mx-auto bg-base-100 m-4 rounded-lg p-4">

                        <p className="font-1 text-2xl font-semibold p-2 bg-base-200 rounded-lg mb-1">Find Setup</p>
                        <div className="w-full bg-base-200 rounded-lg">
                        
                        <div className="relative w-full border-base-300 rounded-lg p-2">  
                            {/* <div className="bg-base-200 p-3">
                            <p className="text-sm font-semibold font-1">Search using social medias, names, or Student ID</p>
                        
                            <div className="join border-b-2">
                                <input type="text" className="input input-secondary input-sm w-full join-item font-2 font-semibold" placeholder="@plastuchino/146475" />
                                <button className="btn join-item btn-sm btn-secondary "> <FaSearch /></button>
                            </div>
                            

                                <div className="bg-base-100 rounded-lg mt-1 p-2">
                                    {(results === "") && (
                                        <FaSpinner className="size-8 animate-spin mx-auto" />
                                    )}
                                    {(results !== "") && (
                                        <>
                                        {results.map((result,i) => (
                                            <div className="my-1 flex border-b-2 flex-row gap-2 items-center justify-items-center p-1 relative ">
                                        <div className="avatar border-2 rounded-full border-secondary">
                                            <div className="w-8 rounded-full">
                                                <img alt="personImg" src={result.img} />

                                            </div>
                                        </div>
                                        <p className="font-1 text-lg font-bold">{result.name} </p>

                                        <div className="absolute right-0 top-[50%] translate-y-[-50%]">
                                        <button className="btn btn-base-100 btn-sm mr-2">
                                            <p className="font-2 ">Setup</p>
                                        </button>
                                        </div>
                                        
                                    </div>
                                        
                                    ))}
                                    </>

                                        
                                    )}

                                </div>
                                </div> */}

                                <div className="bg-base-100 p-3 rounded-lg mx-auto">
                                <p className="font-1 text-lg font-bold">QuickSet</p>


                                {(quickSet.length > 0) ?
                                    quickSet.map((person, i) => (
                                    <div key={i} className="my-1 flex border-b-2 flex-row gap-2 items-center justify-items-center p-1 relative ">
                                        <div className="avatar border-2 rounded-full border-secondary">
                                            <div className="w-8 rounded-full">
                                                <img alt="personImg" src={"https://images-laylnk.s3.amazonaws.com/"+person.img} />

                                            </div>
                                        </div>
                                        <p className="font-1 text-lg font-bold">{person.name} </p>

                                        <div className="absolute right-0 top-[50%] translate-y-[-50%]">
                                        <button className="btn btn-base-100 btn-sm mr-2" onClick={() => processSetup(person.uuid, i)}>
                                            <p className="font-2 ">Setup</p>
                                        </button>
                                        </div>
                                        
                                    </div>
                                )) : 
                                <div className="min-h-[10vh] bg-base-300 flex flex-row items-center justify-center mt-2 rounded-lg">
                                <p className="font-1 font-bold">No one found</p>
                                </div>
                                
                                
                                }
                                
                                
                                </div>
                                
                            <div>

                            </div>
                        </div>
                       
                        </div>
                            <div className="p-2 bg-base-300 mt-2 rounded-lg">
                                <p className="font-1 font-semibold">Changed your mind?</p>
                                <button className="btn btn-secondary btn-sm mt-1" onClick={() => {
                                    props.skipPerson()
                                    props.setSetup(false)
                                    }}><p className="font-2 text-base font-semibold">Go back</p></button>
  
                            </div>
                        </div>
                </>
            )}
            {(!menu) && (
                <div className="z-20 absolute left-0 right-0 mx-auto bg-base-100 m-4 rounded-lg p-4">
                <div className="bg-base-200 p-4 rounded-lg">
                <p className="text-center font-1 font-semibold text-2xl">You rejected {pronouns}</p>
                <FaSadCry className="mx-auto size-12" />
                </div>
                <div className=" bg-base-200 rounded-lg ">
                <p className="font-1 text-center mt-2 text-lg">Would you like to set {pronouns} up?</p>
                <div className="grid grid-cols-2 p-4  rounded-lg items-center justify-items-center">

                    <div className="btn btn-circle btn-lg btn-secondary" onClick={() => {
                        props.skipPerson()
                        props.setSetup(false)
                    }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-8">
  <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
</svg>

                    </div>
                    <div className="btn btn-circle btn-lg btn-warning" onClick={() => setMenu(true)}>
                        <FaCheck className="size-8" />
                    </div>
                   
                </div>
                </div>

                <div>
                    
                </div>

               
            </div>
            )}
            
        </>
        
    )
}



export default Setup

