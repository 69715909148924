import React, {useState, useEffect} from "react"
import Index from "./Index"
import Signup from "./Signup"
import Main from "./Main"
import NoPage from "./NoPage"
import CreateProfile from "./CreateProfile"
import ReportBug from "./ReportBug"
import Login from "./Login"
import {EndpointContext} from "./Contexts"
import Terms from "./Terms"

import { BrowserRouter, Routes, Route } from "react-router-dom";

const endpoint = "https://api.laylnk.com"
function App(props) {

  






    return (
      <EndpointContext.Provider value={endpoint}>
        <BrowserRouter>
          <Routes>
            

            <Route path ="/login" element={<Login />} />
            <Route index path="/" element={<Index />} />
            <Route path="/signup" element={<Signup  />} />
            {/* <Route path="/createProfile" element={<CreateProfile studentId={globalShit} />} /> */}
            <Route path="/main" element={<Main />} /> 
            <Route path="/bug" element={<ReportBug />} /> 
            <Route path="/tos" element={<Terms />} />
            
      



            <Route path="*" element={<NoPage />} />
            
            
        
          </Routes>
        </BrowserRouter>
        </EndpointContext.Provider>
      );
}





export default App;