import React, {useState, useEffect, useContext} from "react"
import UserContext, {EndpointContext} from "./Contexts"




function Notifications(props) {
    const endpoint = useContext(EndpointContext)


    const [notifications, setNotifications] = useState(props.notifs)



    useEffect(() => {

        props.setNotifs(notifications)

    }, [notifications])
    

    const callDismiss = (uuid) => {


        return new Promise(async(resolve) => {
            
            
            const body = {
                contact: uuid
            }
            const response = await fetch(endpoint + '/dismiss', {
                method: "POST",
                mode: "cors",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(body)
            })

            resolve(response.json())

        })

    }


    const processDismiss = (uuid) => {
        callDismiss(uuid).then((res) => {
            if (res.code === "err") {
                window.location.replace("/login")
            }
        })

    }



    return(

        <div className="sm:mt-24 sm:w-2/6 h-screen rounded-lg p-4 bg-base-200 mx-auto">
        <p className="text-center font-1 font-semibold text-3xl w-full bg-base-100 rounded-lg p-2">Notifications</p>
        <div className="mt-1 p-3 rounded-lg bg-base-300">

        {(notifications.length === 0) && (
            <div className=" text-center p-10">
                <p className="font-2 text-lg font-bold">Nothing to see here</p>
                <p className="font-2 font-semibold">Matches will be displayed here</p>
            </div>
        )}
        {(notifications.length>0) && (
            <div className="flex flex-col gap-2">
            {(notifications.map((notif,i) => (
                <div className="p-3 flex-row flex items-center gap-4 bg-base-100 rounded-lg relative" key={i}>
                    <div className="absolute bottom-1 sm:top-[50%] sm:translate-y-[-50%] right-2">
                        <button className="btn btn-sm" onClick={() => {

                            setNotifications((prevNotifications) => prevNotifications.filter((_, index) => index !== i));
                            processDismiss(notif.uuid)
                        }}><p className="font-1">Dismiss</p></button>
                    </div>
                    <div className="w-12 h-12 bg-black rounded-full border-2 border-black">
                        <img alt="ProfilePic" className="w-full h-full object-cover rounded-full" src={"https://images-laylnk.s3.amazonaws.com/"+notif.profilePic} />
                    </div>
                    <div>
                        <p className="font-1 text-lg"><span className=" font-semibold">{notif.name}</span> has matched with you.</p>
                        <p className="font-1">Have fun and be safe! Social Media: @{notif.socialMedia}</p>
                    </div>
            



                </div>
            )))}
            </div>
            
        )}
        

        </div>
        
        


        </div>
    )
}


export default Notifications