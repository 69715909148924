import React from "react"




function NoPage(props) {




    return (

        <>


        <div className="hero min-h-screen bg-primary" data-theme="cupcake">


            <div className="hero-content flex-col text-center">
            
            <p className="font-1 text-8xl font-extrabold">404</p>
            <p className="font-2 text-3xl"><strong>Sorry!</strong> This page doesn't exist</p>
            <button className="btn btn-base-200 mt-4">
                <a href="/" className="text-2xl font-2">Go back to safety</a>
            </button>

            </div>




        </div>



        </>
    )
}


export default NoPage;