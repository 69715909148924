import React, {useState, useEffect, useContext} from "react"
import Navbar from "./Navbar"
import Footer from "./Footer"
import ErrorMessage from "./ErrorMessage"
import Loading from "./Loading"
import {EndpointContext} from "./Contexts"



let timeout;
function Login(props) {
    const endpoint = useContext(EndpointContext)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const [loading, setLoading] = useState(false)
    const [currentUser, setCurrentUser] = useState("")

    


    useEffect(() => {
        timeout = setTimeout(() => {
            setErrorMessage("")
        }, 3000)
        
        return () => {
            clearTimeout(timeout)
    
        }

    }, [errorMessage])
 
    const callLogin = async() => {
        return new Promise(async(resolve) => {
            const body = {
                email: email,
                password: password
            }
    
    
            const response = await fetch(endpoint + "/login", {
                method: "POST",
                mode: "cors",
                credentials: "include",
                headers: {
                  "Content-Type": "application/json"
                },
                body: JSON.stringify(body)
    
            })

            resolve(response.json())
        })
    }

    const processLogin = () => {
        if ((email.length <= 5) || (email.split("@").length != 2)) {
            setErrorMessage("Invalid credentials")
        } else if (password.length < 5) {
            setErrorMessage("Invalid credentials")
         
        } else {
            setLoading(true)
            callLogin().then((res) => {
                setLoading(false)
                if (res.message === "invalid request") {
                    setErrorMessage("Something went wrong")
                } else if (res.message === "invalid credentials") {
                    setErrorMessage("Invalid credentials")
                } else if (res.code === "ok") {
                    console.log("chat they said ok!")

                    window.location.replace("/main")

                } else {
                    console.log(res)
                    setErrorMessage("Something went wrong")
                }

            })
        }



        
    }



    





    return (

        <section data-theme="cupcake">
        
        <div className="min-h-full">
        <Navbar />
        {(errorMessage !== "") && (
                <ErrorMessage error={errorMessage} />
            )} 
        <div className="mt-24 bg-base-100 border-2 rounded-lg p-4 w-fit mx-auto relative">
            
            <div className="text-center p-4 rounded-lg">
                <p className="font-1 font-bold text-2xl">Laylnk</p>
                <div className="mt-12">
                    <div className="flex flex-col gap-2 items-center">
                        <div className="p-1 w-fit rounded-lg">
                        <input type="text" className="input input-secondary font-2 font-semibold" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)}></input>
                        </div>
                        <div className="p-1 w-fit rounded-lg">
                        <input type="password" className="input input-primary font-2 font-semibold" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)}></input>
                        </div>


                        
                        <button className="btn btn-wide btn-primary mt-4" onClick={processLogin}><p className="font-1 text-lg font-bold text-white">Log In</p></button>

                    </div>
                    <p className="underline font-2 text-sm text-center mt-2 select-none cursor-pointer"><a href="/signup">Don't have an account?</a></p>
                    
                </div>
                
            </div>
            
        </div>

        <div className="mt-52">
            <Footer />
        </div>
        </div>
        

        
        






        </section>
    )
}

export default Login