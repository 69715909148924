import React, {useContext, useState, useEffect} from "react"
import {UserContext} from "./Contexts"
import ErrorMessage from "./ErrorMessage"
import {EndpointContext} from "./Contexts"


let timeout;

function Settings(props) {
    const endpoint = useContext(EndpointContext)
    const currentUser = useContext(UserContext)
    const [bio, setBio] = useState(currentUser.bio)
    const [fact, setFact] = useState(currentUser.fact)
    const [tags, setTags] = useState(currentUser.tags)
    const [render, setRender]= useState(0)
    const [tagText, setTagText] = useState("")
    const [images, setImages] = useState([0,0,0,0])
    const [attractedTo, setAttractedTo] = useState(currentUser.attractedTo)
    const [errorMessage, setErrorMessage] = useState("") 
    const [bioLength, setBioLength] = useState(0)
    const processUpdate = () => {
        // First we have to make sure its all good
        window.scroll(0,0)
        let passedTest = true
        if (bio.length < 21) {
            setErrorMessage("Bio must be at least 20 characters")
            passedTest = false
        } else if (bio.length > 150) {
            setErrorMessage("Bio must be at most 150 characters")
            passedTest = false
        } else if (fact.length < 1) {
            setErrorMessage("Please write a fact")
            passedTest = false
 
        } else if (tags.length < 2) {
            setErrorMessage("Please write 2 tags")
            passedTest = false
        } else if (images.length < 1) {
            setErrorMessage("Please have at least 1 image")
            passedTest = false
        }

        
        if (passedTest) {
            callUpdate().then((res) => {
                if (res.message === 'not logged in') {
                    window.location.replace("/login")
                } else if (res.message === "invalid request") {
                    setErrorMessage("Something went wrong")
                } else if (res.message === "success") {
                    setErrorMessage("Profile changed successfully")
                } else {
                    setErrorMessage("Something went wrong")
                }
            })
        }
        
        
    }

    const callUpdate = () => {
        return new Promise(async(resolve) => {
            const body = {
                bio: bio,
                fact: fact,
                tags: tags,
                attractedTo: attractedTo,
                images: images

            }

            const response = await fetch(endpoint + "/update", {
                method: "POST",
                mode: "cors",
                credentials: "include",
                headers: {
                  "Content-Type": "application/json"
                },
                body: JSON.stringify(body)
            })

            resolve(response.json())
        })

        
    }   


    useEffect(() => {
        setBioLength(bio.length)

    }, [bio])


    
    const fileUpload  = () => {
        document.getElementById("fileUpload").click()
    }
    useEffect(() => {
        setImages((prevImages) => {
            let newImages = [...prevImages]
            currentUser.images.map((image,i) => {
                newImages[i] = image
            })
            return newImages
        })
        

    }, [])
    useEffect(() => {
        timeout = setTimeout(() => {
            setErrorMessage("")
        }, 3000)
        
        return () => {
            clearTimeout(timeout)
    
        }

    }, [errorMessage])






    return (

        <div className="mx-auto sm:w-2/6 bg-base-300 p-4 sm:mt-24 relative">
        {(errorMessage !== "") && (

            <ErrorMessage error={errorMessage} />

           
        )}
        <p className="font-1 font-semibold text-3xl">Settings:</p>

        <div className="p-4 mt-2 bg-base-100 rounded-lg">
            <p className="font-2 text-lg font-bold">Basic Info:</p>
            <p className="text-sm font-2">cannot be changed</p>
            <div className="flex flex-col flex-wrap gap-3 mt-3">
                <div className="w-fit rounded-lg flex flex-row items-center justify-items-center gap-2">
                    <div className="bg-base-200 p-2 rounded-lg">
                        <p className="font-1 font-semibold">Name:</p>
                        <div className="">
                            <input readOnly type="text" className="input font-2 font-semibold bg-base-100 input-disabled" value={currentUser.name}/>
                        </div>
                    </div>
                </div>
                <div className=" w-fit rounded-lg flex flex-row items-center justify-items-center gap-2">
                    <div className="bg-base-200 p-2 rounded-lg">
                        <p className="font-1 font-semibold">Email:</p>
                        <div className="">
                            <input readOnly type="text" className="input input-disabled bg-base-100 font-2 font-semibold" value={currentUser.email} />
                        </div>
                    </div>
                </div>

                <div className=" w-fit rounded-lg flex flex-row items-center justify-items-center gap-2">
                    <div className="bg-base-200 p-2 rounded-lg">
                        <p className="font-1 font-semibold">Sex:</p>
                        <div className="">
                            <select readOnly className="select font-2 select-disabled bg-base-100" value={currentUser.gender}>
                                <option>Male</option>
                                <option>Female</option>
                            </select>
                        </div>
                    </div>
                </div>
                


            </div>
            
            
        </div>
            <div className="p-3 bg-base-200 mt-1 rounded-lg">
                <p className="font-1 font-semibold text-lg">Match Profile</p>
                <p className="font-2 text-sm">can be changed</p>
                <div className="flex flex-col gap-2 mt-2">
                    <div className="p-2 bg-base-100 rounded-lg w-full relative">
                        <p className="font-1 font-semibold">Bio:</p>
                        <div className="relative">
                            <textarea className="textarea textarea-primary w-full font-2" onChange={(e) => setBio(e.target.value)} defaultValue={currentUser.bio}></textarea>
                            <div className="absolute bottom-0 right-0 px-1 bg-primary rounded-lg">
                                <p className="font-1">{bioLength}/150</p>
                            </div>
                        </div>
                    </div>
                    <div className="p-2 bg-base-100 rounded-lg w-fit relative">
                        <p className="font-1 font-semibold">Fact:</p>
                        <input className="input input-primary font-1" value={fact} onChange={(e) => setFact(e.target.value)} />
                    </div>

                    <div className="p-2 bg-base-100 rounded-lg w-fit relative">
                        <p className="font-1 font-semibold">Tags:</p>
                        <div className="join">
                            <input type="text" className="input input-primary font-1 join-item" placeholder="Type new tags here" onChange={(e) => {
                                setTagText(e.target.value)
                            }} />
                            <button className="btn join-item btn-secondary font-1" onClick={() => {
                                if ((tagText.length > 0) && (tags.length < 6)) {
                                    setTags((prevTags) => {
                                        return [...prevTags, tagText]
                                    })
                                    setTagText("")
                                }
                                
                            }}>Add Tag</button>
                        </div>
                        <div className="flex gap-4 flex-row flex-wrap p-2 rounded-lg bg-base-200 mt-2">
                        
                        
                        {(tags.map((tag,i) => (
                            <div className="bg-base-300 p-4 rounded-lg relative" key={i}>
                                <div className="absolute top-0 right-0 rounded-full bg-accent text-center cursor-pointer" onClick={() => {
                                    setTags((prevTags) => prevTags.filter((_, index) => index !== i));
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-5 p-1">
                                        <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
                                    </svg>

                                </div>
                                <p className="font-2 font-semibold">{tag}</p>
                            </div>
                            
                        )))}
                        </div>
                        

                    </div>



                </div>



                
            </div>

            <div className="p-3 bg-base-100 mt-1 rounded-lg">
                <p className="font-1 font-semibold text-lg">Dating Preferences</p>
                <div className="mt-2">
                    
                    <div className="bg-base-300 p-2 w-fit rounded-lg">
                    <p className="font-1 font-semibold">Gender Preference:</p>
                    <select className="select select-primary font-1 font-semibold" value={attractedTo} onChange={(e) => {
                        setAttractedTo(e.target.value)
                    }}>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Everyone">Everyone</option>

                    </select>
                    </div>
                    
                </div>

            </div>


            <div className="mt-1 bg-base-200 rounded-lg p-3">
                <p className="font-1 text-lg font-semibold">Your images</p>
                
                <div className="grid grid-cols-2  mt-2  items-center w-fit rounded-lg p-3 gap-2 select-none">
                {images.map((image, i) => (
                    <div key={i}>


                    {(image === 0) && (
                        <div className="p-2 relative rounded-lg bg-base-300 w-32 h-32 skeleton cursor-pointer">
                            <div className="center" onClick={() => {
fileUpload()
                            }
                                
                            }>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
  <path fillRule="evenodd" d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z" clipRule="evenodd" />
</svg>


                            </div>
                            


                        </div>
                    )}
                    {(image !== 0) && (
                        <div className="p-2 relative rounded-lg bg-base-100 w-32 h-32">
                            {(image.substring(0, 4) === "data") && (
                              
                                <img alt="profilePic" src={image} className="w-full h-full object-cover rounded-lg" />
                          
                            )}
                            {(image.substring(0,4) !== "data") && (
                                <img alt="profilePic" src={"https://images-laylnk.s3.amazonaws.com/" + image} className="w-full h-full object-cover rounded-lg" />
                            )}
                            
                            <div className="absolute top-0 right-0 w-6 rounded-full h-6 bg-base-300 cursor-pointer" onClick={(e) => {
                                setImages((prevImages) => {
                                    let newImages = [...prevImages]
                                    newImages[i] = 0
                                    return newImages
                                })
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
  <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
</svg>

                            </div>


                        </div>
                    )}





                    </div>
                    
                    
                ))}

                </div>

            </div>
            <input id="fileUpload" type="file" className="hidden" onChange={(e) => {
                                        setImages((prevImages) => {
                                            const newImages = Array.from(e.target.files)
                                            console.log(newImages)
                                            if (prevImages.length > 4) {
                                                console.log("error too many files 1")
                                                // would return an error message here.
                                            } else {    


                                                let count = 0
                                                let possibleReplacements = []
                                                prevImages.forEach((item,i) => {
                                                    if (item === 0 ) {
                                                        count += 1
                                                        possibleReplacements.push(i)
                                                    }
                                                })
                                                if (count < newImages.length) {
                                                    console.log("error too many files 2")
                                                } else {
                                                    
                                                    newImages.map((newImage, i) => {
                                                        let reader = new FileReader()

                                                        reader.onload = (event) => {
                                                            prevImages[possibleReplacements[i]] = event.target.result;
                                                            setRender(prevRender => prevRender+1)
                                                            // Trigger a state update or re-render here if needed.
                                                        };
                                                        reader.readAsDataURL(newImage);


                                                        


                                                    })


                                                }


                                            }
                                            return prevImages
                                        
                                        })
                                        
                                        // console.log(e.target.files)
                                    
                                    }} multiple accept="image/*"/>
                                    
     
            <div className="mx-auto mt-2 w-fit">
                <button className="btn btn-wide btn-accent" onClick={() => {
                    processUpdate()
                }}><p className="font-1 font-bold text-lg">Change Details</p></button>
            </div>
         
            

            



        </div>
    )
}


export default Settings