import React, {useState, useEffect, useContext} from "react"
import Navbar from "./Navbar"
import { FaInstagram } from "react-icons/fa";
import ErrorMessage from "./ErrorMessage";
import {EndpointContext} from "./Contexts"



let timeout;
function CreateProfile(props) {
    const endpoint = useContext(EndpointContext)
    const [email, setEmail] = useState("")
    const [grade, setGrade] = useState("9")
    const [bioText, setBioText] = useState("")
    const [render, setRender]= useState(0)

    const [images, setImages] = useState([0,0,0,0])
    const [tags, setTags] = useState([])
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [gender, setGender] = useState("Male")
    const [tagInput, setTagInput] = useState("")
    const [formSlide, setFormSlide] = useState(1)


    const [fact, setFact] = useState("")
    const [socialMedia, setSocialMedia] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const [attractedTo, setAttractedTo] = useState("Female")
    const MAXFORMVALUE = 4

    useEffect(() => {
        console.log("this scrollUp was called")
        document.getElementById('scrollable').scrollTo({top: 0})
    }, [formSlide, errorMessage])
    const callSignup = async () => {
        return new Promise(async(resolve) => {

            setImages((prevImages) => {
                let newImages = []
                prevImages.map((prevImage) => {
                    if (prevImage === 0) {
                        // do nothing
                    } else {
                        newImages.push(prevImage)
                    }
                })
                return newImages
            })

            
            const body = JSON.stringify(
                {
                    studentId: props.studentId,
                    email: email,
                    name: username,
                    socials: socialMedia,
                    grade: grade,
                    bio: bioText,
                    fact: fact,
                    tags: tags,
                    password: password,
                    gender: gender,
                    attractedTo: attractedTo,
                    images: images




                }
            )


            const headers = {
                method: "POST",
                mode: "cors",
                credentials: "include",
                headers: {
                  "Content-Type": "application/json"
                },
                body: body

            }

            const response = await fetch(endpoint + "/signup", headers)

            resolve(response.json())

        })
    }


    const processSignup = () => {
        callSignup().then((res) => {
            if (res.message === "invalid request") {
                setErrorMessage("Something went wrong")
            } else if (res.message === "unknown error") {
                setErrorMessage("Something went wrong")
            } else if (res.message === "invalid code") {
                window.location.replace("/signup")
            } else if (res.message === "success") {
                window.location.replace("/main")
            } else {
                setErrorMessage("Something went wrong")
            }
        })

    }


    const fileUpload  = () => {
        document.getElementById("fileUpload").click()
    }

    useEffect(() => {
        document.getElementById('formModal').showModal()
    },[])
    useEffect(() => {
        timeout = setTimeout(() => {
            setErrorMessage("")
        }, 3000)
        
        return () => {
            clearTimeout(timeout)
    
        }

    }, [errorMessage])

    return (

        <section data-theme="cupcake">

            <Navbar />
            <dialog className="w-full h-full relative min-h-screen modal" id="formModal">
                <div id="scrollable" class="modal-box w-fit max-w-[100vw] relative h-fit overflow-y-auto">
                    <p className="absolute top-4 right-4 p-2 w-10 h-10 bg-primary font-1 text-lg font-semibold text-center rounded-full">{formSlide}/{MAXFORMVALUE}</p>
                    {(errorMessage !== "") && (
                        <ErrorMessage error={errorMessage} />
                    )}
                    
                    {(formSlide === 1) && (


                        <>


                            <p className="font-2 font-semibold text-2xl">Login Information:</p>
                            
                            <div className="bg-base-200 p-3 rounded-lg mt-1">
                            <p className="font-2  text-center">These credentials will be used to login into your account</p>
                                <div className="p-4 w-fit mx-auto flex flex-col gap-4">
                                    <div className="w-fit mx-auto">
                                        <input value={email} type="text" className="input font-2 text-center input-primary" placeholder="Personal Email" onChange={(e) => setEmail(e.target.value)} />
                                    </div> 
                                    
                                    <div className="flex-col flex p-2 bg-base-300 rounded-lg">
                                            <input value={password} type="password" className="input font-2 text-center input-secondary" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                                        <p className=" mt-1">Password Requirements</p>
                                        <ul className="list-disc ml-6">
                                            <li>
                                                <p className="font-light text-sm">Must be between 7-12 characters</p>
                                            </li>
                                            <li>
                                                <p className="font-light text-sm">Don't put your studentvue password</p>
                                            </li>
                                            
                                        </ul>
                                        
                                    </div>
                                    


                                </div>


                            </div>
                        </>
                    )}
                    
                    
                    {(formSlide === 2) && (
                        <>
                        <div className="">
                        <h3 class="font-bold text-2xl font-2">Create your Profile</h3>


                    
                    </div>

                    <div className="mt-2 p-4 rounded-lg bg-base-300">
                    <p className="font-1 text-lg font-semibold">Basic Details:</p>
             
                    <div className="flex flex-col gap-4">

                    <div className="p-2 bg-base-100 rounded-lg w-fit">
                        <p className="font-2 font-semibold inline-block mr-2">Name:</p>
                        <input className="input  inline-block input-secondary" value={username} placeholder="Ex: Becker Smith" onChange={(e) => {
                            setUsername(e.target.value)
                        }} />
                    </div>

                    <div className="p-2 bg-base-100 rounded-lg w-fit font-2">
                        <p className="mr-2 inline-block font-semibold">Grade:</p>
                        <select className="select font-2 inline-block select-primary" value={grade} onChange={(e) => {
                            setGrade(e.target.value)
                        }}>
                            <option value="9">Freshman</option>
                            <option value="10">Sophomore</option>
                            <option value="11">Junior</option>
                            <option value="12">Senior</option>

                        </select>
                    </div>
                        <div className="p-2 bg-base-100 rounded-lg w-fit font-2">
                                <p className="font-2 inline mr-2 font-semibold">Sex:</p>
                                <select className="select select-primary font-2" value={gender} onChange={(e) => setGender(e.target.value)}>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                </select>
                            </div>
                            <div className="p-2 bg-base-100 rounded-lg w-fit font-2">
                                <p className="font-2 inline mr-2 font-semibold">Gender Preference:</p>
                                <select className="select select-primary font-2" value={attractedTo} onChange={(e) => setAttractedTo(e.target.value)}>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Everyone">Everyone</option>
                                </select>
                            </div>

                    </div>
                        
                    </div>
                    


       
                    
                        </>
                    )}

                    {(formSlide === 3) && (
                        <>
                            <div className="">
                                <p className="font-1 text-2xl font-semibold">Create Dating Profile</p>


                                <div className="mt-2 p-4 rounded-lg bg-base-200">
                                    <div>

                                    </div>
                                    <p className="font-2 font-semibold text-2xl">Add Images</p>
                                    <input id="fileUpload" type="file" className="hidden" onChange={(e) => {
                                        setImages((prevImages) => {
                                            const newImages = Array.from(e.target.files)
                                            console.log(newImages)
                                            if (prevImages.length > 4) {
                                                console.log("error too many files 1")
                                                // would return an error message here.
                                            } else {    
                                                
                                                let count = 0
                                                let possibleReplacements = []
                                                prevImages.forEach((item,i) => {
                                                    if (item === 0 ) {
                                                        count += 1
                                                        possibleReplacements.push(i)
                                                    }
                                                })
                                                if (count < newImages.length) {
                                                    console.log("error too many files 2")
                                                } else {
                                                    
                                                    newImages.map((newImage, i) => {
                                                        let reader = new FileReader()

                                                        reader.onload = (event) => {
                                                            prevImages[possibleReplacements[i]] = event.target.result;
                                                            setRender(prevRender => prevRender+1)
                                                            // Trigger a state update or re-render here if needed.
                                                        };
                                                        reader.readAsDataURL(newImage);


                                                        


                                                    })


                                                }


                                            }
                                            return prevImages
                                        
                                        })
                                        
                                        // console.log(e.target.files)
                                    
                                    }} multiple accept="image/*"/>
                                    <div className="grid grid-cols-2 gap-4 p-4 bg-base-100 w-fit rounded-lg mt-2">

                                    
                                    {images.map((image, i) => (
                                            <div key={i}>
                                        {(image === 0) && (
                                            <div className="bg-base-200 skeleton rounded-lg w-32 h-32 relative select-none cursor-pointer hover:scale-105 transition-all" onClick={fileUpload}>
                                                <p className="absolute top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%] font-2 text-2xl">+</p>
                                            </div>
                                        )}
                                        {(image !== 0) && (
                                            <>
                                            <div className="bg-secondary border-2 border-primary rounded-lg w-32 h-32 relative select-none hover:scale-105 transition-all">
                                                <div id={i} className="absolute top-1 right-1 w-6 h-6 text-center rounded-full bg-error text-white" onClick={() => {
                                                    setImages((prevImages) => {
                                                        prevImages[i] = 0
                                                        return prevImages
                                                    })
                                                }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
  <path fill-rule="evenodd" d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
</svg>

                                                </div>
                                                <img alt="profileImg" src={image} className="w-full h-full object-cover rounded-lg"></img>
                                            </div>
                                                

                                            </>
                                        )}
                                        </div>
                                            
                                            


                                        
                                        



                    ))}
</div>



                                </div>





                            </div>


                        </>
                    )}
                    {(formSlide === 4) && (
                        <div>
                            <p className="font-2 text-2xl font-bold">Add your Personality</p>


                            <div className="mt-2 p-4 rounded-lg bg-base-300 sm:grid flex flex-col sm:grid-cols-2 items-center justify-center grid-cols-1 sm:gap-4">
                                <div className="flex sm:flex-row flex-col flex-wrap items-center justify-items-center p-8">
                              
                                    <p className="font-1 text-lg mr-4">Add your Bio:</p>
                                    <div className="w-fit h-fit relative">
                                        <textarea onChange={(e) => {
                                            setBioText(e.target.value)
                                        }} className="textarea font-2 text-lg textarea-primary textarea-lg"  placeholder='I like the simple things in life' maxLength="150" />                            
                                        <p className="absolute bottom-0 right-0 bg-secondary rounded-lg h-fit p-1 w-fit  text-center font-2 text-sm">{bioText.length}/150</p>
                                    
                       
                                    </div>
                                    
                                </div>

                                <div className="flex sm:flex-row flex-col  items-center col-span-1 p-8">
                                    <p className="font-1 text-lg mr-2">Interesting Fact about yourself:</p>
                                    <input value={fact} onChange={(e) => setFact(e.target.value)} className="input input-bordered input-primary font-1 input-lg" maxLength="50" placeholder="I have a pet alligator" />
                                    
                                </div>
                                <div className="flex sm:flex-row flex-col items-center gap-4 border-t-2 border-accent p-8 col-span-2">
                                    <div>
                                        <p className="font-1 text-lg font-semibold">Link your social media: </p>
                                        <p className="text-xs font-1">(will only be shown to people you match with)</p>
                                    </div>
                                    
                                    <div className="join">
                                        <div>
                                            <input type="text" value={socialMedia} onChange={(e) => setSocialMedia(e.target.value)} className="input input-bordered join-item input-primary font-1" placeholder="@mocotinder" />
                                        </div>
                                    <div>
                                        <select className="select select-bordered select-disabled join-item select-ghost font-2" disabled>
                                        {/* <option value="phone number">Phone #</option> */}
                                            <option value="instagram">Instagram</option>
                                            {/* <option value="snapchat">Snapchat</option>
                                            
                                            <option value="email">Email</option>
                                            <option value="discord">Discord</option>  */}
                                        </select>
                                    </div>
                                    
                                    </div>
                                </div>
                                
                                
                            </div> 
                            
                        </div>
                    )}
                    {(formSlide === 3) && (
                        <div className="p-4 bg-base-200 mt-4 rounded-lg">
                        <div className="p-4">
                            <div className="flex flex-col gap-2">
                                
                                <div className="mr-2">
                                    <p className="font-1 text-lg font-semibold">Add your Tags:</p>
                                    <p className="text-xs font-2">Up to 6</p>
                                </div>
                                <div className="">
                                    <div className="w-fit h-full relative">
                                        <p className="absolute top-0 right-0 w-6 h-6 text-center bg-primary hover:scale-105 select-none cursor-pointer rounded-full" onClick={(e) => {
                                            if ((tagInput.length > 1) && (tags.length < 6)) {
                                                tags.push(tagInput)
                                                setTagInput("")
                                            }
                                        }}>+</p>
                                        <input maxLength="25" value={tagInput} onChange={(e) => {setTagInput(e.target.value)}}  type="text" className="input input-primary font-2" placeholder="Ex: 4ft 11" />
                                    </div>
                            
                                    
                                    


                                </div>
                                {(tags.length > 0) && (
                                    <div className="flex flex-row flex-wrap w-fit gap-4 p-5 bg-base-100 rounded-lg">
                                    {tags.map((tag,i) => (
                                        <div key={i} id={i} className="bg-base-300 p-3  rounded-lg relative select-none">
                                            <button className="absolute top-[-10px] rounded-full right-[-5px] select-none bg-secondary w-6 h-6 text-center" onClick={(e) => {
                                                setTags((prevTags) => prevTags.filter((_, index) => index !== i));
                                            }}>
                                                <p className="font-2">x</p>
                                            </button>
                                            <p className="font-2 font-semibold text-sm">{tag}</p>
                                        </div>
                                    ))}

                                    </div>
                                )}
                                
                                
                            </div>
                            
                        </div>



                    </div>
                    )}
                    
                    <div className="">
                    <p className="ml-1 font-1 font-semibold text-sm inline-block">By Creating an Account, you accept to our <a className="underline" href="/tos">Terms of Service</a></p>
                    <input type='checkbox' className="checkbox inline-block translate-y-2 ml-2"></input>
                    </div>
                    <div className="mt-2 ">
                        <button className="btn btn-secondary mr-2" onClick={() => {
                            if (formSlide < MAXFORMVALUE) {
                                let failedTest = false




                                if (formSlide === 1) {
                                    
                                    // Here we will verify the shit.
                                    if (email.split("@").length !== 2) {
                                        setErrorMessage("Invalid Email")
                                        failedTest = true
                                    } else if (email.length < 5) {
                                        setErrorMessage("Invalid Email")
                                        failedTest = true
                                    } else if (password.length <= 7) {
                                        setErrorMessage("Invalid Password")
                                        failedTest = true
                                    } else if (password.length >= 12) {
                                        setErrorMessage("Invalid Password")
                                        failedTest = true
                                    }
                                } else if (formSlide === 2) {
                                    if (username.length < 2) {
                                        setErrorMessage("Invalid Name")
                                        failedTest = true
                                    }

                                } else if (formSlide === 3) {
                                    if (tags.length < 2) {
                                        setErrorMessage("Need at least 2 tag")
                                        failedTest = true
                                    } else if (images.length < 3) {
                                        setErrorMessage("Need at least 2 image")
                                        failedTest = true
                                        
                                    }
                                }
                                if (!failedTest) {
                                    setFormSlide(prevSlide => prevSlide+1)
                                }
                            } else {

                                if (formSlide === 4) {
                                    console.log("did this happen?")
                                    if (bioText.length < 21) {
                                        setErrorMessage("Bio must be at least 20 characters")
                                        return
                                    } else if (fact.length < 1) {
                                        setErrorMessage("Enter a fact about yourself")
                                        return
                                    } else if (socialMedia.length <1) {
                                        setErrorMessage("Enter your @")
                                        return
                                    }

                                    processSignup()
                                    
                                }
                            }
                        }}>
                            <p className="font-2 font-extrabold">Continue</p>
                        </button>
                        <button className="btn" onClick={() => {
                            if (formSlide > 1) {
                                setFormSlide(prevSlide=> prevSlide-1)
                            }
                        }}>
                            <p className="font-2 font-semibold">Go back</p>
                        </button>
                    </div>





                     {/* <div class="modal-action">
      <form method="dialog">

        <button class="btn">Close</button>
      </form>
    </div> */}
  </div>

                




            </dialog>

            

        



        </section>
    )
}


export default CreateProfile