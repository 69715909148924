import React from 'react';





function ErrorMessage(props) {






    return (


        <>
            <div className='absolute top-4 left-[50%] translate-x-[-50%] p-3 text-white z-20 bg-error rounded-lg'>
                <p className='font-1 font-semibold text-lg'>{props.error}</p>



            </div>



        </>
    )
}


export default ErrorMessage;