import React from 'react';
import ReactDOM from 'react-dom';
import "./index.css";
import App from './components/App';

const root = document.getElementById('root')

ReactDOM.render(<App/>, root)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))

