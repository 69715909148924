import React, {useState, useEffect, useContext} from "react"
import Navbar from "./Navbar"
import Footer from "./Footer"
import { FaLock } from "react-icons/fa";
import Loading from "./Loading"
import ErrorMessage from "./ErrorMessage"
import CreateProfile from "./CreateProfile"
import {EndpointContext} from "./Contexts"

let timeout;

function Signup(props) {
    const endpoint = useContext(EndpointContext)
    const [studentId, setStudentId] = useState("")
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState("") 
    const [createProfile, setCreateProfile] = useState(false)
    const [verifyEmail, setVerifyEmail] = useState(false)
    const [code, setCode] = useState("")

    useEffect(() => {
        timeout = setTimeout(() => {
            setErrorMessage("")
        }, 3000)
        
        return () => {
            clearTimeout(timeout)
    
        }

    }, [errorMessage])
    


    const callForm = async () => {
        return new Promise(async(resolve) => {
            const request = await fetch(endpoint + "/checkStatus", {
                method: "POST",
                mode: "cors",
                credentials: "include",
                body: JSON.stringify({
                    studentId: studentId,
                }),
                headers: {
                  "Content-Type": "application/json"
                }
              })
    
    
              resolve(request.json())
        })
        
    }


    const submitForm = () => {
        if (String(studentId).length < 3) {
            setErrorMessage("Invalid Student Id")
            return
        }
        

        setLoading(true)
        callForm().then((res) => {
            setLoading(false)
            if (res.code === "ok") {
                setVerifyEmail(true)
                // Verify email code
                
            } else if ((res.message === "already confirmed")) {
                setCreateProfile(true)
                // window.location.replace("/createprofile");
            } else if (res.message === "already exists") {
                window.location.replace("/login");
            } else {
                
                setErrorMessage(res.message)
            }
        })
        
    }

    const callCode = async () => {
        return new Promise(async(resolve) => {
            const request = await fetch(endpoint + "/checkCode", {
                method: "POST",
                mode: "cors",
                credentials: "include",
                body: JSON.stringify({
                    code: code,
                    studentId: studentId,
                }),
                headers: {
                  "Content-Type": "application/json"
                }
              })
    
    
              resolve(request.json())
        })
        
    }




    const checkCode = () => {

        callCode().then((res) => {
            if (res.message === "account exists") {
                window.location.replace("/login")
            } else if (res.message === "code not found") {
                setErrorMessage("Invalid code or code already used")
            } else if (res.message === "verify not found") {
                window.location.replace("/signup")
            }
            
            else if (res.code === "err") {
                setErrorMessage("Something went wrong")
            } else if (res.message === "success") {
                setCreateProfile(true)
            }
            
            else {
                setErrorMessage("Something went wrong")
            }
        })




    }





    return (


        <>

        {(createProfile) && (
            <CreateProfile studentId={studentId} />
        )}

        {(!createProfile) && (
            <>
            <Navbar />
        {(loading) && (

<Loading />
)}



        {(!verifyEmail) && (
            <>
            <div className="bg-base-100 pt-24 relative" data-theme="dark">
            
            {(errorMessage !== "") && (
                <ErrorMessage error={errorMessage} /> 
            )}

            <div className="mx-auto w-fit min-w-md bg-base-300 p-4 rounded-lg max-w-3xl">
            

            <div className="grid sm:grid-cols-2 grid-cols-1  mt-4 gap-1 select-none">
                <div className="sm:col-span-2 grid-cols-1">
                    <p className="text-center text-2xl font-semibold py-2 text-white font-2 sm:text-left">Laylnk Signup</p>
                </div>
                
            

                <div className="flex flex-col bg-base-100 p-4 rounded-lg gap-4 sm:text-left text-center">
                    <div>

                        <p className="text-white font-1">Student ID:</p>
                        <input value={studentId} onChange={(e) => setStudentId(e.target.value)} className="input input-primary input-ghost mt-1 font-2" placeholder="Ex: 145223" />

                    </div>
                    

                    <button className="btn btn-wide btn-accent sm:m-0 mx-auto" onClick={submitForm}>
                        <p className="font-1 text-lg font-semibold">Sign up</p>

                    </button>
                    

                </div>

                <div className=" rounded-lg bg-base-100">
                <div className="w-full bg-base-200 p-4 rounded-t-lg">
                    <div className="w-fit mx-auto text-white text-4xl">
                    🔒
                    </div>

                </div>
                <p className="font-2 font-semibold text-white p-2 text-lg text-center bg-base-300 ">No student credentials needed</p>
                <p className="p-2 font-1 text-white text-lg font-semibold text-center">Enter your student ID to confirm your identity</p>
                

                </div>



            </div>

            
                

            </div>



        </div>
        <div className="absolute bottom-0 w-full sm:block hidden">
        <Footer />
        </div>
            </>

        )}

        {(verifyEmail) && (
            <>


            <div className="min-h-[40vh] hero relative" data-theme="dark">
            {(errorMessage !== "") && (
                <ErrorMessage error={errorMessage} /> 
            )}

                <div className="hero-content bg-base-300 text-center md:w-2/6 w-full">
                    <div className="">

                        <p className="font-1 font-semibold text-2xl">Verify your email</p>
                        <p className="italic font-2">this allows us to ensure the safety of every laylnk user </p>


                        <div className="p-4 bg-base-100 mt-4">
                            <p className="font-1">An email was sent to {studentId}@mcpsmd.net</p>
                        <div className="join join-vertical">
                            
                            <input value={code} onChange={(e) => setCode(e.target.value)} maxLength='40' className="input input-bordered  mt-4 join-item input-lg text-center font-1" placeholder="Code:" />
                            <div className="btn join-item btn-lg font-2" onClick={checkCode}>
                                Verify Identity
                            </div>
                        </div>
                        </div>
                        
                        
                    </div>

                   

                </div>


            </div>
            <div className="absolute bottom-0 w-full sm:block hidden">
        <Footer />
        </div>

            </>
        )}

        


            </>

        )}

       
        






        </>
    )
}


export default Signup