import React, {useState, useEffect, createContext, useContext} from "react"
import Navbar from "./Navbar"
import Footer from "./Footer"
import MainNav from "./MainNav"
import Settings from "./Settings"
import ReportBug from "./ReportBug"
import {UserContext, EndpointContext} from "./Contexts"

import Notifications from "./Notifications"
import { FaHeart, FaX, FaHeartBroken, FaSmileWink, FaCheck, FaSadTear, FaFlag, FaBars} from "react-icons/fa";

import Setup from "./Setup"
let notClicked = true

let timeout;

function Main(props) {
    const endpoint = useContext(EndpointContext)
    const [timer, setTimer] = useState()
    const [mobileNav, setMobileNav] = useState(false)
    const [screen, setScreen] = useState(false)
    // true = computer
    // false = phone
    const [currentImg, setCurrentImg] = useState(0)
    const [currentFeed, setCurrentFeed] = useState(0)
    const [setup, setSetup] = useState(false)
    const [like, setLike] = useState(false)
    const [currentUser, setCurrentUser] = useState("")
    const [feed, setFeed] = useState([])
    const [menu, setMenu] = useState("Feed")
    const [notifications, setNotifications] = useState([])
    

    useEffect(() => {
        if (window.outerWidth >= 640) {
            setMobileNav(true)
        } else {
            setMobileNav(false)
        }
        
        
    },[menu])


    const getFeed = () => { 
        return new Promise(async(resolve) => {
            const response = await fetch(endpoint + "/getFeed", {
                method: "GET",
                mode: "cors",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                }
            })
            resolve(response.json())
        })
        
    }

    const callLike = (like) => {
        return new Promise(async(resolve) => {
            const receiver = feed[currentFeed].uuid
            const body = {
                receiver: receiver,
                like: like

            }


            const response = await fetch(endpoint + "/like", {
                method: "POST",
                mode: "cors",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(body)
            })

            resolve(response.json())



        })
    }
    const processDislike = () => {
        setSetup(true)
        callLike(false).then((res) => {
            if (res.message === "not logged in") {
                window.location.replace("/login")
            } else if (res.message === "like") {

            } else if (res.message === "match") {

            } else {
                console.log("something went wrong")
            }
        })
    }

    const processLike = () => {
        setLike(true)
        callLike(true).then((res) => {
            if (res.message === "not logged in") {
                window.location.replace("/login")
            } else if (res.message === "like") {

            } else if (res.message === "match") {

            } else {
                console.log("something went wrong")
            }
        })
        

    }


    
   
    const getUser = () => {
        

        return new Promise(async(resolve) => {
            const response = await fetch(endpoint + "/getinfo", 
                {
                    method: "GET",
                    mode: "cors",
                    credentials: "include",
                    headers: {
                      "Content-Type": "application/json"
                    }
                })

    

            resolve(response.json())
            
            })




    }

    const getNotifications = () => {
        return new Promise(async(resolve) => {
            const response = await fetch(endpoint + "/getNotifications", {
                method: "GET",
                mode: "cors",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                }

            })

            resolve(response.json())

        })
    }


    useEffect(() => {
        if (window.outerWidth >= 640) {
            setScreen(true)
        } else {
            setScreen(false)
        }
    }, [])
    
    useEffect(() => {
        console.log("this happened")
        getUser().then((res) => {
            if (res.code === "ok") {
                setCurrentUser(res.message)
            } else {
                window.location.replace("/login")
            }
        })



        getFeed().then((res) => {
            if (res.code === "ok") {
                setFeed(res.message)
            } else {
                window.location.replace("/login")
            }
        })

        getNotifications().then((res) => {
            if (res.code === "ok") {
                setNotifications(res.message)
            } 
            else {
                window.location.replace("/login")
            }
        })
    },[])

    function calculateTimer() {
        var d = new Date();
        var h = d.getHours();
        var m = d.getMinutes();
        var s = d.getSeconds();
        var secondsUntilEndOfDate = (24*60*60) - (h*60*60) - (m*60) - s

        let formattedDate = new Date(null)
        formattedDate.setSeconds(secondsUntilEndOfDate)
        const result = formattedDate.toISOString().slice(11,19)
        setTimer(result)
    }
    
    setTimeout(calculateTimer, 1000)

    function skipPerson() {
        setCurrentFeed(currentFeed => currentFeed+1)
    }

    useEffect(() => {
        setCurrentImg(0)
    }, [currentFeed])
    useEffect(() => {
        
        if (like) {
            timeout = setTimeout(() => {
                setLike(false)
                setCurrentFeed(prevFeed=> prevFeed+1)
            },1000)
        }
        
        return () => {
            clearTimeout(timeout)
        }
    }, [like])

    // const feed = 
    // [
    //     {
    //         name: "Sebastian H. 1",
    //         img: ["https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg", "https://t4.ftcdn.net/jpg/03/83/25/83/360_F_383258331_D8imaEMl8Q3lf7EKU2Pi78Cn0R7KkW9o.jpg"],
    //         grade: "Junior",
    //         bio: "I like the simple things in life and I also like to yap I like the simple things in life and I also like to yap I like the simple things in life and I also like to yap",
    //         fact: "Im broke asf", 
    //         socialType: "Instagram",
    //         tags: ["8ft 11", "Walter Johnson School", "Academic Weapon"]




    //     },
    //     {
    //         name: "Sebastian H. 2",
    //         img: ["https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg", "https://www.google.com/url?sa=i&url=https%3A%2F%2Fstock.adobe.com%2Fsearch%3Fk%3Dperson&psig=AOvVaw0xmoRnc6LCctfgTEVSQvrn&ust=1719313663440000&source=images&cd=vfe&opi=89978449&ved=0CBEQjRxqFwoTCNjW09GM9IYDFQAAAAAdAAAAABAT", "https://t4.ftcdn.net/jpg/03/83/25/83/360_F_383258331_D8imaEMl8Q3lf7EKU2Pi78Cn0R7KkW9o.jpg"],
    //         grade: "Junior",
    //         bio: "I like the simple things in life and I also like to yap",
    //         fact: "Im broke asf", 
    //         socialType: "Instagram",
    //         tags: ["8ft 11", "Walter Johnson School", "Academic Weapon"]




    //     },
    //     {
    //         name: "Sebastian H. 3",
    //         img: ["https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg", "https://www.google.com/url?sa=i&url=https%3A%2F%2Fstock.adobe.com%2Fsearch%3Fk%3Dperson&psig=AOvVaw0xmoRnc6LCctfgTEVSQvrn&ust=1719313663440000&source=images&cd=vfe&opi=89978449&ved=0CBEQjRxqFwoTCNjW09GM9IYDFQAAAAAdAAAAABAT", "https://t4.ftcdn.net/jpg/03/83/25/83/360_F_383258331_D8imaEMl8Q3lf7EKU2Pi78Cn0R7KkW9o.jpg"],
    //         grade: "Junior",
    //         bio: "I like the simple things in life and I also like to yap",
    //         fact: "Im broke asf", 
    //         socialType: "Instagram",
    //         tags: ["8ft 11", "Walter Johnson School", "Academic Weapon"]




    //     },
        
        
    // ]






    return (
        <UserContext.Provider value={currentUser}>
        <section data-theme='cupcake' className="flex flex-row">
            

           
            {(mobileNav) && (
                <MainNav setMenu={setMenu} notifications={notifications.length} />
            )}
                
            {(screen) && (
                <>
                
                {(menu.trim() === "Notifications") && (
                <Notifications notifs={notifications} setNotifs={setNotifications} />
            )}

            {(menu.trim() === "Bug") && (
                <ReportBug />
            )}
            {(menu.trim() === "Settings") && (
                <Settings />
            )}

            {(menu.trim() === "Feed") && (
                <div className="w-full h-screen bg-base-200 flex flex-col gap-2 relative">
                
                
                <div className="m-4 bg-base-100 p-4 rounded-lg">
                    <p className="font-1 text-2xl font-bold">Your Feed</p>

                    

                
                


                



                </div>
                {(currentFeed > feed.length-1) && (
                            <div className="m-4 p-4 bg-base-100 w-fit mx-auto rounded-lg absolute left-[50%] translate-x-[-50%] top-[40%] translate-y-[-50%]">
                            <p className="font-1 text-3xl font-semibold bg-primary p-3 rounded-lg text-center">Out of Swipes</p>
                            <div className="mt-4 p-3 bg-base-200 rounded-lg">
                            <p className="text-center font-2">Come back in:</p>
                                <div className="p-4 rounded-lg text-center bg-accent">
                                    <p className="font-1 text-2xl">{timer}</p>
                                </div>

                            </div>

                            </div>
                        )}
                {((currentFeed < feed.length) && (feed !== undefined)) && (
                    <div className="mt-24 mx-auto w-fit rounded-lg p-4 bg-base-300 select-none">
                    <div className="w-96 relative rounded-lg">

                        {(setup) && (

                            <>
                                <Setup setSetup={setSetup} currentUser={feed[currentFeed]} skipPerson={skipPerson} />

                            </>
                        )}
                        {(like) && (
                            <>
                            <div className="absolute top-0 left-0 z-10 h-full w-full select-none bg-green-500 opacity-60"></div>
                            <div className="z-20 absolute left-0 right-0 mx-auto w-full bg-green-500">
                               <p className="text-center font-2 text-6xl font-semibold p-4">Like!</p>
                               <div className="w-full p-4">
                                <div className="w-fit mx-auto p-4 rounded-full">
                                <FaCheck className="size-12" />
                                </div>
                                
                               </div>
                               
                            </div>
                            </>
                            
                        )}
                        
                        <div className="relative">
                            {}
                            <img alt="laylnk profile" src={"https://images-laylnk.s3.amazonaws.com/"+feed[currentFeed].img[currentImg]} className="object-cover max-h-96 w-full rounded-lg cursor-pointer" onClick={() => {
                                setCurrentImg((prevImg) => {
                                    if (prevImg === feed[currentFeed].img.length-1) {
                                        return 0
                                    } else {
                                        return prevImg+1
                                    }
                                })
                                notClicked = false
                            }} />
                            <div className="absolute top-0 right-0 p-2 bg-primary rounded-tr-lg">
                                <p className="font-2 text-lg font-semibold">{currentImg+1}/{feed[currentFeed].img.length}</p>
                            </div>

                            {(notClicked) && (
                                <>
                                    <div className="absolute bottom-0 w-fit mx-auto left-0 right-0 bg-black p-1 rounded-lg text-white animate-bounce">
                                        <p className="font-2">Click on the image to see other images</p>
                                    </div>
                                </>
                            )}

                            
                        </div>
                        
                        <div className=" p-2 bg-base-200 rounded-lg mt-2 relative">
                            <div className="w-full h-fit relative">
                                {(feed[currentFeed].name !== undefined) && (
                                    <p className="font-2 font-semibold text-3xl w-full ">{feed[currentFeed].name.split(" ")[0]}</p>
                                )}
                                
                                {/* <div className="absolute right-2 top-[50%] translate-y-[-50%] btn btn-circle btn-sm btn-accent ">
                                    <FaFlag />
                                </div> */}
                            </div>
                            
                            <div className="flex flex-row gap-2 mt-1 ">
                            {feed[currentFeed].tags.map((tag,i) => (
                                <div key={i} className="p-2 rounded-lg border-2 cursor-pointer hover:bg-primary border-primary w-fit">
                                    <p className="font-1 text-sm font-bold">{tag}</p>
                                </div>
                            ))}
                               

                            </div>
                            <p className="mt-2 p-1 font-2 text-lg font-semibold break-all">{feed[currentFeed].bio}</p>
                            

                            <div className="mt-2 p-3 bg-base-100 rounded-lg">
                                <p className="font-1 "><span className="font-semibold">Interesting Fact about me: </span>{feed[currentFeed].fact}</p>
                                
                            </div>
                            <div className="grid grid-cols-2 w-fit mx-auto gap-12 p-4 bg-base-200 rounded-lg mt-4">
                                    <div className="btn btn-circle btn-primary btn-lg rounded-full text-white group" onClick={(e) => {
                                        processDislike()
                                        
                                    }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-8 group-hover:hidden">
  <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
</svg>
                                        <FaHeartBroken  className="size-8 hidden group-hover:block"  />
                                    </div>

                                    
                                    <div className="btn btn-circle btn-lg btn-secondary transition-all  rounded-full text-white group " onClick={(e) => {
                                        
                                        processLike()

                                        
                                    }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-8 group-hover:hidden">
                                        <path d="m11.645 20.91-.007-.003-.022-.012a15.247 15.247 0 0 1-.383-.218 25.18 25.18 0 0 1-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0 1 12 5.052 5.5 5.5 0 0 1 16.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 0 1-4.244 3.17 15.247 15.247 0 0 1-.383.219l-.022.012-.007.004-.003.001a.752.752 0 0 1-.704 0l-.003-.001Z" />
                                    </svg>
                                    <FaSmileWink className="size-8 hidden group-hover:block" />

                                    </div>
                                </div>

                            

                        
                        </div>
                        
                    </div>
                
                        


                </div>
                    
                )}
                
            </div>
            )}
                </>

            )}
         

           

            {(!mobileNav) && (
                <div className="sm:hidden block relative h-full w-full">
                <button className="btn btn-base-300 btn-outline fixed bottom-2 left-2 z-20" onClick={() => { setMobileNav(true);}}><FaBars className="size-6" /></button>
                {(menu.trim() === "Notifications") && (
                <Notifications notifs={notifications} setNotifs={setNotifications} />
                )}

            {(menu.trim() === "Bug") && (
                <ReportBug />
            )}
            {(menu.trim() === "Settings") && (
                <Settings />
            )}


            {(menu.trim() === "Feed") && (
                <div className="w-full min-h-screen h-full bg-base-200 flex flex-col gap-2 relative">
             
                
                <div className="m-4 bg-base-100 p-4 rounded-lg">
                    <p className="font-1 text-2xl font-bold">Your Feed</p>

                    

                
                






                </div>
                {(currentFeed > feed.length-1) && (
                            <div className="m-4 p-4 bg-base-100 sm:w-fit w-full mx-auto rounded-lg absolute left-[50%] translate-x-[-50%] top-[40%] translate-y-[-50%]">
                            <p className="font-1 text-3xl font-semibold bg-primary p-3 rounded-lg text-center">Out of Swipes</p>
                            <div className="mt-4 p-3 bg-base-200 rounded-lg">
                            <p className="text-center font-2">Come back in:</p>
                                <div className="p-4 rounded-lg text-center bg-accent">
                                    <p className="font-1 text-2xl">{timer}</p>
                                </div>

                            </div>

                            </div>
                        )}
                {((currentFeed < feed.length) && (feed !== undefined)) && (
                    <div className="sm:mt-24 mx-auto w-fit rounded-lg p-1 bg-base-300 select-none">
                    <div className="sm:w-96 relative rounded-lg">

                        {(setup) && (

                            <>
                                <Setup setSetup={setSetup} skipPerson={skipPerson} />

                            </>
                        )}
                        {(like) && (
                            <>
                            <div className="absolute top-0 left-0 z-10 h-full w-full select-none bg-green-500 opacity-60"></div>
                            <div className="z-20 absolute left-0 right-0 mx-auto w-full bg-green-500">
                               <p className="text-center font-2 text-6xl font-semibold p-4">Like!</p>
                               <div className="w-full p-4">
                                <div className="w-fit mx-auto p-4 rounded-full">
                                <FaCheck className="size-12" />
                                </div>
                                
                               </div>
                               
                            </div>
                            </>
                            
                        )}
                        
                        <div className="relative">
                            {}
                            <img alt="laylnk profile" src={"https://images-laylnk.s3.amazonaws.com/"+feed[currentFeed].img[currentImg]} className="object-cover max-h-96 w-full rounded-lg cursor-pointer" onClick={() => {
                                setCurrentImg((prevImg) => {
                                    if (prevImg === feed[currentFeed].img.length-1) {
                                        return 0
                                    } else {
                                        return prevImg+1
                                    }
                                })
                                notClicked = false
                            }} />
                            <div className="absolute top-0 right-0 p-2 bg-primary rounded-tr-lg">
                                <p className="font-2 text-lg font-semibold">{currentImg+1}/{feed[currentFeed].img.length}</p>
                            </div>

                            {(notClicked) && (
                                <>
                                    <div className="absolute bottom-0 w-fit mx-auto left-0 right-0 bg-black p-1 rounded-lg text-white animate-bounce">
                                        <p className="font-2">Click on the image to see other images</p>
                                    </div>
                                </>
                            )}

                            
                        </div>
                        
                        <div className=" p-2 bg-base-200 rounded-lg mt-2 relative">
                            <div className="w-full h-fit relative">
                                {(feed[currentFeed].name !== undefined) && (
                                    <p className="font-2 font-semibold text-3xl w-full ">{feed[currentFeed].name.split(" ")[0]}</p>
                                )}
                                
                                <div className="absolute right-2 top-[50%] translate-y-[-50%] btn btn-circle btn-sm btn-accent ">
                                    <FaFlag />
                                </div>
                            </div>
                            
                            <div className="flex flex-row gap-2 mt-1 ">
                            {feed[currentFeed].tags.map((tag,i) => (
                                <div key={i} className="p-2 rounded-lg border-2 cursor-pointer hover:bg-primary border-primary w-fit">
                                    <p className="font-1 text-sm font-bold">{tag}</p>
                                </div>
                            ))}
                               

                            </div>
                            <p className="mt-2 p-1 font-2 text-lg font-semibold break-all">{feed[currentFeed].bio}</p>
                            

                            <div className="mt-2 p-3 bg-base-100 rounded-lg">
                                <p className="font-1 "><span className="font-semibold">Interesting Fact about me: </span>{feed[currentFeed].fact}</p>
                                
                            </div>
                            <div className="grid grid-cols-2 w-fit mx-auto gap-12 p-4 bg-base-200 rounded-lg mt-4">
                                    <div className="btn btn-circle btn-primary btn-lg rounded-full text-white group" onClick={(e) => {
                                        processDislike()
                                        
                                    }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-8 group-hover:hidden">
  <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
</svg>
                                        <FaHeartBroken  className="size-8 hidden group-hover:block"  />
                                    </div>

                                    
                                    <div className="btn btn-circle btn-lg btn-secondary transition-all  rounded-full text-white group " onClick={(e) => {
                                        
                                        processLike()

                                        
                                    }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-8 group-hover:hidden">
                                        <path d="m11.645 20.91-.007-.003-.022-.012a15.247 15.247 0 0 1-.383-.218 25.18 25.18 0 0 1-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0 1 12 5.052 5.5 5.5 0 0 1 16.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 0 1-4.244 3.17 15.247 15.247 0 0 1-.383.219l-.022.012-.007.004-.003.001a.752.752 0 0 1-.704 0l-.003-.001Z" />
                                    </svg>
                                    <FaSmileWink className="size-8 hidden group-hover:block" />

                                    </div>
                                </div>

                            

                        
                        </div>
                        
                    </div>
                
                        


                </div>
                    
                )}
                
            </div>
            )}

                </div>
            )}
            
            
            
           
            
          

        {/* No more spaghetti code! */}


        <div>

        </div>













        </section>
        </UserContext.Provider>
    )
}

export default Main
