import React from "react";

import Navbar from "./Navbar"

import Footer from "./Footer"


function Terms(props) {






    return (

        <section data-theme="cupcake" className="min-h-screen">
            <Navbar />
            <div className="bg-base-100 w-full h-full">
                <div className="p-3 bg-base-200 w-5/6  mx-auto  mt-10 flex flex-col gap-2 rounded-lg">
                    <div className="h-full bg-base-300 rounded-lg p-3">
                        <p className="font-1 text-2xl font-bold">Terms of Service:</p>

                    </div>

                    <div className="p-3 bg-base-100 rounded-lg">
                        
                        <p className="font-1 font-semibold text-lg">By using this service, you <strong>consent to Laylnk </strong>collecting the following info:</p>
                        
                        <ul className="list-disc ml-5 font-1 font-semibold">
                            <li>Full Name</li>
                            <li>Email</li>
                            <li>Social Media</li>
                            <li>Grade</li>
                            <li>Sex</li>
                            <li>Gender Preference</li>

                        </ul>
                        <div className="p-2 mt-1 bg-base-300 rounded-lg">
                            <p className="font-2 font-bold">Here's how this data would be used:</p>
                            <p className="rounded-lg font-2">This data would be used to curate your feed as well as be displayed to other users.</p>
                        </div>
                        
                        
                    </div>
                    <div className="bg-base-100 rounded-lg p-3">
                        <p className="font-1 font-semibold text-lg">Why does Laylnk ask for Studentvue credentials?</p>

                        <p className="font-2">To ensure all users are students, we initially ask for your credentials to confirm that you are a student.</p>
                        <div className="p-2 mt-1 bg-base-200 rounded-lg">
                            <p className="font-1 ">Note: This information is <span className="font-bold">not saved</span> and is <span className="font-bold">never accessed</span> after your initial signup.</p>


                        </div>
                    </div>
                    <div className="bg-base-100 rounded-lg p-3">
                        <p className="font-1 font-semibold text-lg">Account deletion policy</p>
                        <p className="font-2">After requesting an account deletion via your account settings, it will take up to <span className="font-semibold">24 hours or less </span>to process until your account is fully deleted.</p>

                    </div>



                </div>
                

            </div>
            <div className="mt-8">
            <Footer />
            </div>
            
            
            





        </section>
    )
}


export default Terms